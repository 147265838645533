<template>
  <div class="upsell-items-page" data-v-step="11">
    <BackTitle class="mb-40" @click="goBack">Upsell merchandise</BackTitle>
    <div class="grid-2" data-v-step="13">
      <Guard permission="upsellItems.create" v-slot="{ isAvailable }">
        <CreateButton v-if="isAvailable" @click="goToCreation">
          Create upsell merchandise
        </CreateButton>
      </Guard>
      <Loader
        v-if="isLoading"
        size="m"
        color="primary"
        class="mx-auto grid-2__full-item"
      />
      <div v-else-if="!upsellItems.length" class="empty grid-2__full-item">
        You haven't created upsell merchandise yet
      </div>
      <template v-else>
        <UpsellItemCard
          v-for="item in upsellItems"
          :key="item.id"
          :title="item.name"
          :description="item.description"
          :image="item.image"
          :price="item.price"
          :is-paused="item.isPaused"
          :is-deleting="deletingItemId === item.id"
          :is-loading="pausingItemId === item.id"
          @remove="handleRemove(item)"
          @edit="handleEdit(item)"
          @pause="handlePause(item)"
          @duplicate="handleDuplicate(item)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import { mapActions, mapState } from "vuex";
import CreateButton from "@/components/common/CreateButton";
import UpsellItemCard from "@/components/upsell-items/UpsellItemCard";
import dialog from "@/plugins/dialog";
import Guard from "@/components/common/Guard";

export default {
  name: "UpsellItemsPage",
  components: { Guard, UpsellItemCard, CreateButton, BackTitle },
  data() {
    return {
      isLoading: false,
      deletingItemId: null,
      pausingItemId: null,
    };
  },
  computed: {
    ...mapState({
      upsellItems: (state) => state.upsellItems.list,
    }),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchUpsellItems();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchUpsellItems: "upsellItems/fetchUpsellItems",
      deleteUpsellItem: "upsellItems/deleteUpsellItem",
      updateUpsellItem: "upsellItems/updateUpsellItem",
    }),
    goBack() {
      this.$router.push({
        name: "Settings",
      });
    },
    goToCreation() {
      this.$router.push({
        name: "CreateUpsellItem",
      });
    },
    async handleRemove(item) {
      const confirmed = await dialog.confirm({
        title: "Are you sure?",
        message: "All upsell merchandise data will be lost",
        okText: "Delete",
        cancelText: "Cancel",
      });

      if (confirmed) {
        try {
          this.deletingItemId = item.id;
          await this.deleteUpsellItem(item.id);
          await this.fetchUpsellItems();
        } finally {
          this.deletingItemId = null;
        }
      }
    },
    handleEdit(item) {
      this.$router.push({
        name: "EditUpsellItem",
        params: {
          id: item.id,
        },
      });
    },
    async handlePause(item) {
      try {
        this.pausingItemId = item.id;
        await this.updateUpsellItem({
          id: item.id,
          data: {
            isPaused: !item.isPaused,
          },
        });
        await this.fetchUpsellItems();
      } finally {
        this.pausingItemId = null;
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleDuplicate({ id, ...item }) {
      this.$router.push({
        name: "CreateUpsellItem",
        params: {
          duplicate: item,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.upsell-items-page {
  display: flex;
  flex-direction: column;
}
</style>
