<template>
  <button class="create-button" @click="$emit('click')">
    <Icon name="plus" :size="32" color="primary" />
    <slot />
  </button>
</template>

<script>
export default {
  name: "CreateButton",
};
</script>

<style lang="scss" scoped>
.create-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background: transparent;
  border: 2px solid $secondary-400;
  box-shadow: $box-shadow-small;
  border-radius: 8px;
  color: $primary;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  transition: 0.3s;
  min-height: 140px;

  @media (min-width: $media-laptop) {
    height: 100%;
  }

  &:hover {
    background-color: #286eed05;
    border-color: #286eed30;
  }

  &:active {
    background-color: #286eed10;
    border-color: #286eed35;
  }
}
</style>
